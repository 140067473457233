/* Generic */
body { background-color: #f4f7fc !important; }

/* Bootstrap Custom Tables */
.table { border-color: rgb(126 142 177 / 12%);}
.table tbody tr:nth-of-type(odd) { background-color: #f8f9fa; }
.table tr.clickable { cursor: pointer; }
.table tr.clickable:hover { background-color: #eef4fa; }
.table td { vertical-align: middle; } 
.table td.clickable:hover { background-color:rgba(0, 0, 0, 0.05); }

/* Bootstrap Custom Cards */
.card { box-shadow: 0 2px 4px rgb(126 142 177 / 12%); border-radius: .2em !important; border: 0 solid #f4f7fc !important; }
.card-header { background-color: #fff; border-color: rgb(126 142 177 / 20%); }
.card > .table td:first-child, .card > .table th:first-child { padding-left: 1em; }
.card > .table td:last-child, .card > .table th:last-child { padding-right: 1em; }
.card > .card-body { border-bottom: 1px solid rgb(126 142 177 / 15%); }
.card > .card-body:last-child { border: none;}
.card > .card-body .nav-tabs { padding-left: 1em; }

/* Link Button */
a.btn-sm { margin-left: 0.125em; margin-right: 0.125em; }
a.btn-sm > svg { min-width: 12px;}

/* Popup */
.popup-content { margin: auto;  background: rgb(255, 255, 255); border-radius: .25em; border-color: rgb(126 142 177 / 12%); width: 100%; max-width: 95%; max-height: 95%; overflow: auto;}
.popup-md-content { max-width: 85%; max-height: 90%; }
@media (min-width: 792px) { .popup-content { width: 744px; } .popup-md-content { width: 624px; }}
@media (min-width: 1016px) { .popup-content { width: 984px; } .popup-md-content { width: 864px; }}
@media (min-width: 1224px) { .popup-content { width: 1164px; } .popup-md-content { width: 1044px; }}
[role='tooltip'].popup-content { width: 200px;  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;}
.popup-overlay { background: rgba(0, 0, 0, 0.5);}
[data-popup='tooltip'].popup-overlay {  background: transparent;}
.popup-arrow { color: rgb(255, 255, 255);}

/* React DatePicker */
.custom-floating { position: relative; }
.custom-floating .react-datepicker-wrapper input { border: 1px solid #ced4da; padding: 26px 12px 10px 12px; line-height: 20px; border-radius: 0.375rem; width: 100%; }
.custom-floating label { position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0.65; padding: 1rem 0.75rem; text-align: start; transform-origin: 0 0; transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); pointer-events: none;}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
